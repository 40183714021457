import React from 'react'

import { AccountPageLayout } from '@guiker/account-components'
import { webappRoutes } from '@guiker/account-shared'
import { findPayoutMethodLink, InvoiceContent, useInvoiceContext } from '@guiker/payment-app-components'
import { Flex } from '@guiker/react-framework'

import { PayoutMethodNestedContextProvider, useAuthenticationContext, usePayoutMethodNestedContext } from '../../hooks'
import { useT } from '../../i18n'

const InvoiceScreenContent: React.FC = () => {
  const { invoice } = useInvoiceContext()
  const { payoutMethod } = usePayoutMethodNestedContext()
  const { user } = useAuthenticationContext()
  const payoutMethodLinkProps = findPayoutMethodLink({ invoice, payoutMethod, user })
  return <InvoiceContent user={user} payoutMethodLinkProps={payoutMethodLinkProps} />
}

export const ViewInvoiceScreen: React.FC = () => {
  const { invoice, isLoading } = useInvoiceContext()
  const { user } = useAuthenticationContext()
  const { tMain: tNav } = useT({ screenName: 'sideMenu.navItem' })
  const showPayoutMethod = !!user && !!invoice && invoice?.receiver?.userId === user.id

  const breadcrumbItems = [
    { label: tNav('wallet'), to: '../../' },
    { label: tNav('payments'), to: webappRoutes.root.wallet().payments().path },
    { label: tNav('paymentHistory'), to: webappRoutes.root.wallet().payments().history().path },
    { label: invoice?.id },
  ]

  return (
    <PayoutMethodNestedContextProvider id={showPayoutMethod ? invoice?.receiver?.payoutMethod?.id : undefined}>
      <AccountPageLayout breadcrumbItems={breadcrumbItems} isLoading={isLoading}>
        <Flex flexDirection='column' maxWidth={900}>
          <InvoiceScreenContent />
        </Flex>
      </AccountPageLayout>
    </PayoutMethodNestedContextProvider>
  )
}
