import React, { useState } from 'react'

import { Listing as BaseListing } from '@guiker/base-entity'
import {
  AnyColor,
  BikeParkingIcon,
  CheckmarkMiniIcon,
  Collapse,
  Divider,
  DrawerChestIcon,
  ElevatorIcon,
  Flex,
  Grid,
  GymIcon,
  H4,
  JanitorIcon,
  LaundryRoomIcon,
  Link,
  makeStyles,
  ParkBenchIcon,
  ParkingIcon,
  PBold,
  PoolIcon,
  RecreationRoomIcon,
  TextButton,
  theme,
  useTranslation,
  WheelChairAccessibleIcon,
} from '@guiker/react-framework'

import { InfoLabel } from '../InfoLabel'

const useStyle = makeStyles(
  {
    button: {
      marginTop: theme.spacing(2),
    },
    notIncluded: {
      marginTop: theme.spacing(1),
    },
  },
  {
    name: 'AmenityComponent',
  },
)

const AmenityComponent: React.FC<{
  buildingAmenities: BaseListing.BuildingAmenities<boolean>
  onClickEdit?: () => void
}> = ({ buildingAmenities, onClickEdit }) => {
  const { t } = useTranslation(['common-rentalListing', 'main-rentalListing'])
  const [showNotIncluded, setShowNotIncluded] = useState(false)
  const classes = useStyle()

  const amenitieInfos: {
    adornment: React.FC<{ color?: AnyColor }>
    label: keyof BaseListing.BuildingAmenities<boolean>
  }[] = [
    { adornment: BikeParkingIcon, label: 'bikeParking' },
    { adornment: ParkBenchIcon, label: 'communalOutdoorSpace' },
    { adornment: JanitorIcon, label: 'concierge' },
    { adornment: ElevatorIcon, label: 'elevator' },
    { adornment: GymIcon, label: 'gym' },
    { adornment: LaundryRoomIcon, label: 'laundryRoom' },
    { adornment: DrawerChestIcon, label: 'storage' },
    { adornment: ParkingIcon, label: 'indoorParking' },
    { adornment: ParkingIcon, label: 'outdoorParking' },
    { adornment: PoolIcon, label: 'pool' },
    { adornment: RecreationRoomIcon, label: 'recreationRoom' },
    { adornment: WheelChairAccessibleIcon, label: 'wheelchairAccessible' },
  ]

  const filtered = {
    available: amenitieInfos.filter(({ label }) => buildingAmenities[label]),
    unavailable: amenitieInfos.filter(({ label }) => !buildingAmenities[label]),
  }

  return (
    <div>
      <Flex justifyContent='space-between'>
        <H4>{t('main-rentalListing:buildingInfo')}</H4>
        {onClickEdit && <Link onClick={onClickEdit}>{t('common:actions.edit')}</Link>}
      </Flex>
      <PBold mb={2}>{t('common-rentalListing:amenities.title')}</PBold>
      <Grid container spacing={1}>
        {filtered.available.map(({ label }) => (
          <InfoLabel
            key={label}
            adornment={CheckmarkMiniIcon}
            label={t(`common-rentalListing:amenities.${label}`)}
            isAvailable={buildingAmenities[label]}
          />
        ))}
      </Grid>

      <div className={classes.notIncluded}>
        <Collapse in={showNotIncluded}>
          <Divider mb={3} />
          <Grid container spacing={1}>
            {filtered.unavailable.map(({ label }) => (
              <InfoLabel
                key={label}
                label={t(`common-rentalListing:amenities.${label}`)}
                isAvailable={buildingAmenities[label]}
              />
            ))}
          </Grid>
        </Collapse>
      </div>

      <TextButton onClick={() => setShowNotIncluded(!showNotIncluded)} className={classes.button}>
        {t(`common-rentalListing:amenities.actions.${showNotIncluded ? 'hideNotIncluded' : 'showNotIncluded'}`)}
      </TextButton>
    </div>
  )
}

export { AmenityComponent }
