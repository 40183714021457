import React from 'react'

import { DataAndMeta } from '@guiker/api-definition'
import {
  Capabilities,
  mainPathBuilder,
  Payout,
  payoutMethodInfoBuilder,
  PayoutMethodStatus,
  PayoutMethodWithPayouts,
} from '@guiker/payout-shared'
import {
  Button,
  FindHomeIcon,
  Flex,
  H2,
  InvestIcon,
  Link,
  PageSection1,
  Pagination,
  PBold,
  PSmall,
  theme,
  useMediaQuery,
} from '@guiker/react-framework'
import { money } from '@guiker/shared-framework'

import { Highlight, PayoutMethodStatusChip, PayoutTable } from '../../components'
import { useT } from '../../i18n'

type Props = {
  payoutMethod: PayoutMethodWithPayouts
}

const CapabilitiesHighlight: React.FC<Props> = ({ payoutMethod }) => {
  const { tMain } = useT({ screenName: 'screens.payoutMethod' })

  return (
    <Highlight
      header={tMain('capabilities.label')}
      value={
        <Flex gap={1} flexDirection='column'>
          {payoutMethod.capabilities.map((c) => {
            switch (c) {
              case Capabilities.INVESTMENT:
                return (
                  <Flex gap={1}>
                    <InvestIcon width={18} color={60} />
                    <PBold mb={0}>{tMain('capabilities.investment')}</PBold>
                  </Flex>
                )
              case Capabilities.RENTAL:
                return (
                  <Flex gap={1}>
                    <FindHomeIcon width={18} color={60} />
                    <PBold mb={0}>{tMain('capabilities.rental')}</PBold>
                  </Flex>
                )
            }
          })}
        </Flex>
      }
    />
  )
}

const BalanceHighlights: React.FC<Props> = ({ payoutMethod }) => {
  const { tMain } = useT({ screenName: 'screens.payoutMethod' })
  const { balance, currency } = payoutMethod

  const total = money.fromAmount(balance.total, currency).toString('onlySymbol')
  const inTransit = money.fromAmount(balance.inTransit, currency).toString('onlySymbol')

  return (
    <>
      <Highlight
        header={tMain('total')}
        value={
          <Flex gap={1} alignItems='center'>
            <PBold mb={0}>{total}</PBold>
            <PSmall mb={0} color={30}>
              {currency}
            </PSmall>
          </Flex>
        }
      />
      <Highlight
        header={tMain('inTransit')}
        value={
          <Flex gap={1} alignItems='center'>
            <PBold mb={0}>{inTransit}</PBold>
            <PSmall mb={0} color={30}>
              {currency}
            </PSmall>
          </Flex>
        }
      />
    </>
  )
}

export const PayoutMethodInfo: React.FC<Props> = ({ payoutMethod }) => {
  const { tMain } = useT({ screenName: 'screens.payoutMethod' })

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const hasPayouts = payoutMethod?.payouts?.length > 0

  const fetcher = (pagination: Pagination) => {
    return Promise.resolve({
      meta: {
        total: payoutMethod.payouts.length,
        totalPages: Math.ceil(payoutMethod.payouts.length / pagination.perPage),
        page: pagination.page,
        perPage: pagination.perPage,
      },
      data: payoutMethod.payouts
        .slice((pagination.page - 1) * pagination.perPage, pagination.page * pagination.perPage)
        .map((p) => ({ ...p, payoutMethod })),
    } as DataAndMeta<Payout[]>)
  }

  return (
    <PageSection1
      hasDivider={false}
      title={
        <Flex gap={2} flexDirection={isMobile ? 'column' : 'row'} alignItems='center'>
          <H2 mb={0}>{payoutMethodInfoBuilder(payoutMethod).getDisplay()}</H2>
          <PayoutMethodStatusChip payoutMethod={payoutMethod} />
        </Flex>
      }
      topActions={
        [PayoutMethodStatus.ENABLED, PayoutMethodStatus.REJECTED].includes(payoutMethod.status) ? (
          <Link to={mainPathBuilder.root.byId(payoutMethod.id).path}>
            <Button>{tMain('cta')}</Button>
          </Link>
        ) : undefined
      }
    >
      <Flex flexDirection='column' gap={10}>
        <Flex gap={isMobile ? 3 : 10} flexDirection={isMobile ? 'column' : 'row'}>
          <CapabilitiesHighlight payoutMethod={payoutMethod} />
          {hasPayouts && <BalanceHighlights payoutMethod={payoutMethod} />}
        </Flex>
        {hasPayouts && <PayoutTable fetcher={fetcher} />}
      </Flex>
    </PageSection1>
  )
}
