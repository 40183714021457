import React, { useState } from 'react'

import { PlaidLink } from '@guiker/payment-verification-components'
import { buildBankInformation, PayoutMethod, VerificationProvider } from '@guiker/payout-shared'
import { Box, DeepPartial, TextField, useFormContext, useGetInputProps, useTranslation } from '@guiker/react-framework'
import { CountryCode } from '@guiker/use-plaid-link'

import { ManualInputContent } from '..'

type FormContentProps = {
  defaultValue: DeepPartial<PayoutMethod> & { bankInformation: ReturnType<typeof buildBankInformation> }
  readOnly?: boolean
  countryCode?: CountryCode
}

const tPrefix = 'payout:payoutMethod.bankInformation'
const namespaces = ['payout', 'main-payout', 'common']

const ConnectOnlineBankingContent: React.FC<FormContentProps> = ({
  readOnly,
  defaultValue,
  countryCode = CountryCode.Canada,
}) => {
  const { t } = useTranslation([])
  const [isProviderInvoked, setIsProviderInvoked] = useState(false)
  const formPrefix = 'bankInformation.verification'
  const inputProps = useGetInputProps({ formPrefix, namespaces, tPrefix, readOnly, defaultValue })
  const formContext = readOnly ? undefined : useFormContext()

  const handleOnSelect = ({ publicToken, accountId }) => {
    formContext?.setValue(`${formPrefix}.provider`, VerificationProvider.PLAID, { shouldDirty: true })
    formContext?.setValue(`${formPrefix}.publicToken`, publicToken, { shouldDirty: true })
    formContext?.setValue(`${formPrefix}.details.bankAccountId`, accountId, { shouldDirty: true })
    setIsProviderInvoked(true)
  }

  return (
    <>
      <Box display='none'>
        <TextField type='hidden' {...inputProps('provider')} />
        <TextField type='hidden' {...inputProps('publicToken')} />
        <TextField type='hidden' {...inputProps('details.bankAccountId')} />
      </Box>
      {!isProviderInvoked && defaultValue.bankInformation.details?.accountNumber && (
        <ManualInputContent readOnly={true} defaultValue={defaultValue} countryCode={countryCode} />
      )}
      <div>
        <PlaidLink countryCode={countryCode} label={t(`${tPrefix}.connectBankAccount`)} onSelect={handleOnSelect} />
      </div>
    </>
  )
}

export { ConnectOnlineBankingContent }
