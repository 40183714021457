import { mainRoutes } from '@guiker/my-investment-shared'
import { Outlet, webAppRouterBuilder } from '@guiker/react-framework'

import {
  Dashboard,
  PropertyRentalScreen,
  PropertyUnitRentalScreen,
  RentalListingsScreen,
  RentCollectionScreen,
  RentPayoutScreen,
  TenantInstalmentRedirect,
  TenantScreen,
  TenantsScreen,
  ViewTenantInstalmentScreen,
} from '../screens'
import { ViewPayoutScreen } from '../screens/ViewPayoutScreen/ViewPayoutScreen'
import { ListingNestedRouter } from './ListingNestedRouter'
import { ListingUnitNestedRouter } from './ListingUnitNestedRouter'
import { PayoutNestedRouter } from './PayoutNestedRouter'
import { RentPaymentPlanNestedRouter } from './RentPaymentPlanNestedRouter'

export const Router = webAppRouterBuilder(mainRoutes, {
  root: {
    Component: Outlet,
    routes: {
      root: Dashboard,
      investmentDashboard: Dashboard,
      rentalDashboard: {
        routes: {
          root: Dashboard,
          listings: {
            routes: {
              byListingId: {
                Component: ListingNestedRouter,
                routes: {
                  root: PropertyRentalScreen,
                  units: {
                    routes: {
                      byInventoryId: {
                        Component: ListingUnitNestedRouter,
                        routes: {
                          root: PropertyUnitRentalScreen,
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
      todos: Dashboard,
      rentCollection: RentCollectionScreen,
      rentPayout: RentPayoutScreen,
      rentalListings: RentalListingsScreen,
      rentalTenancy: {
        Component: Outlet,
        routes: {
          tenantInstalments: {
            Component: Outlet,
            routes: {
              byId: TenantInstalmentRedirect,
            },
          },
          payouts: {
            Component: Outlet,
            routes: {
              byPayoutId: {
                Component: PayoutNestedRouter,
                routes: {
                  root: ViewPayoutScreen,
                },
              },
            },
          },
          bookings: {
            Component: Outlet,
            routes: {
              byBookingId: {
                Component: RentPaymentPlanNestedRouter,
                routes: {
                  tenants: {
                    Component: Outlet,
                    routes: {
                      byTenantId: TenantScreen,
                    },
                  },
                  tenantInstalments: {
                    Component: Outlet,
                    routes: {
                      byTenantInstalmentId: ViewTenantInstalmentScreen,
                    },
                  },
                },
              },
            },
          },
        },
      },
      tenants: TenantsScreen,
    },
  },
})
