import React from 'react'

import { mainPathBuilder } from '@guiker/conversation-shared'
import { legacyRoutes } from '@guiker/legacy-routes'
import { useAuthenticatedPaymentPayInMethodContext } from '@guiker/payment-methods-context'
import { PlaidLink } from '@guiker/payment-verification-components'
import { PageLayout, Spinner, useMutation, useNavigate, useT } from '@guiker/react-framework'

const UpdatePaymentVerificationScreen: React.FC = () => {
  const { tMain, tShared } = useT({ domain: 'payment', screenName: 'screens.updateVerificationToken' })
  const { data: payInMethod, isFetching, apiClient } = useAuthenticatedPaymentPayInMethodContext()
  const navigate = useNavigate()

  const { mutate: markVerificationTokenRefreshed } = useMutation(
    () => apiClient.markVerificationTokenRefreshed({ pathParams: { payInMethodId: payInMethod.id } }),
    { onSuccess: () => navigate(mainPathBuilder.root.messages.path()) },
  )

  const handleOnSelect = ({}) => {
    if (payInMethod.legacyId) {
      navigate(legacyRoutes.MyHome, { isExternalLink: true })
    } else {
      markVerificationTokenRefreshed()
    }
  }

  if (isFetching) {
    return <Spinner />
  }

  return (
    <PageLayout maxWidth={900} title={tMain('title')} subtitle={tMain('description')}>
      <PlaidLink
        label={tShared('connector.link')}
        countryCode={payInMethod.region}
        plaidAccessToken={payInMethod.details['plaidAccessToken']}
        onSelect={handleOnSelect}
      />
    </PageLayout>
  )
}

export { UpdatePaymentVerificationScreen }
