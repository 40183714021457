import React from 'react'

import { useAuthenticationContext } from '@guiker/authentication-context'
import { mainPathBuilder } from '@guiker/my-investment-shared'
import { Payout, TransferProjection, TransferScopeType, WithPayoutMethod, WithTransfers } from '@guiker/payment-shared'
import { Payout as PayoutComponent, transferHelper } from '@guiker/payout-app-components'
import { Flex, FullScreenSpinner, PageLayout, PageSection2, PageSection4, PBig, Table } from '@guiker/react-framework'
import { formatDate, money } from '@guiker/shared-framework'

import { PayoutActivityLog } from '../../components'
import { usePayoutContext } from '../../context'
import { useSideMenuItems } from '../../hooks'
import { useT } from '../../i18n'
import { usePayoutTransferColumns } from '../../utils'

export const ViewPayoutScreen: React.FC = () => {
  const { tMain } = useT({ screenName: 'screens.payout' })
  const { user } = useAuthenticationContext()
  const { payout, isLoading, events, isPaidOffline } = usePayoutContext()
  const sideMenuNavItems = useSideMenuItems(user?.metadata)
  const columns = usePayoutTransferColumns()

  if (isLoading) return <FullScreenSpinner />

  const breadcrumbItems = [
    { label: tMain('breadcrumb.dashboard'), to: mainPathBuilder.root.path },
    { label: tMain('breadcrumb.rental'), to: mainPathBuilder.root.rentalDashboard.path },
    {
      label: tMain('breadcrumb.date', {
        date: formatDate(payout.createdAt, { alwaysDisplayYear: false }),
      }),
    },
  ]
  const isZeroAmount = payout.amount <= 0

  return (
    <PageLayout
      sideMenuProps={{ items: sideMenuNavItems }}
      breadcrumbItems={breadcrumbItems}
      title={tMain('title', { date: formatDate(payout.createdAt) })}
    >
      <PageSection2
        title={tMain('header')}
        topActions={
          <Flex gap={1} alignItems='center'>
            <PBig fontWeight={500} mb={0}>
              {money.fromAmount(payout.amount, payout.currency).toString()}
            </PBig>
            {!isZeroAmount && <PayoutComponent.PayoutStatusChip payout={payout} />}
          </Flex>
        }
      >
        <PageSection4 title={tMain('destinationAccount')}>
          <PayoutComponent.PayoutMethodLabel payoutMethod={payout.payoutMethod} isPaidOffline={isPaidOffline} />
        </PageSection4>
        <PageSection4 title={tMain('timeline')}>
          <PayoutComponent.PayoutTimeline payout={payout} events={events} />
        </PageSection4>
        <PageSection4 title={tMain('transactions')}>
          <Table data={transferHelper.getFullTransfers(payout)} columns={columns} />
        </PageSection4>
        <PageSection4 title={tMain('breakdown')}>
          <PayoutComponent.RentalPayoutBreakdown
            payout={payout as WithPayoutMethod<WithTransfers<Payout, TransferProjection<TransferScopeType.INVOICE>>>}
          />
        </PageSection4>
        {!!events?.length && !isZeroAmount && (
          <PageSection4 title={tMain('activityLog.title')}>
            <PayoutActivityLog events={events} />
          </PageSection4>
        )}
      </PageSection2>
    </PageLayout>
  )
}
