import React from 'react'

import { AccountPageLayout } from '@guiker/account-components'
import { webappRoutes as accountRoutes, webappRoutes } from '@guiker/account-shared'
import { ListPaymentMethods, TopInvoicesTable } from '@guiker/payment-app-components'
import { ListLateInvoices } from '@guiker/payment-app-components'
import {
  Button,
  FullScreenSpinner,
  Link,
  PageSection1,
  RouterLink,
  TextButton,
  useNavigate,
  useQuery,
} from '@guiker/react-framework'

import { PaymentEmptyState } from '../../components'
import { usePaymentApiClient } from '../../hooks'
import { useT } from '../../i18n'

const PAYMENT_URL = accountRoutes.root.wallet().payments().path

const PaymentsScreenContent: React.FC = () => {
  const { tBase } = useT({})
  const { tMain } = useT({ screenName: 'components.listLateInvoices' })
  const navigate = useNavigate()
  const apiClient = usePaymentApiClient()
  const invoicesUrl = webappRoutes.root.wallet().payments().invoice().path

  const { data: lateInvoices, isLoading: isLateInvoicesLoading } = useQuery([`readLateOrFailedInvoices`], () =>
    apiClient.readAllLateInvoices(),
  )
  const { data: paymentMethods, isLoading: isPaymentMethodsLoading } = useQuery(
    ['list-payInMethods'],
    () => apiClient.readAllPayInMethods(),
    {
      retry: 1,
    },
  )
  const { data: topLegacyInvoices, isLoading: isReadAllLateInvoicesLoading } = useQuery(
    [`readTopLegacyInvoices`],
    () =>
      apiClient.readAllLegacyInvoices({
        queryParams: { sort: 'createdAt', sortOrder: -1, perPage: 5, page: 1 },
      }),
    {
      retry: 1,
    },
  )
  const { data: topInvoices, isLoading: isReadAllInvoiceLoading } = useQuery(
    [`readTopInvoices`],
    () =>
      apiClient.readAllInvoices({
        queryParams: { sort: 'createdAt', sortOrder: -1, perPage: 5, page: 1 },
      }),
    {
      retry: 1,
    },
  )

  if (isReadAllInvoiceLoading || isReadAllLateInvoicesLoading || isLateInvoicesLoading || isPaymentMethodsLoading)
    return <FullScreenSpinner />
  return !topInvoices?.data?.length &&
    !topLegacyInvoices?.data?.length &&
    !paymentMethods?.length &&
    !lateInvoices?.length ? (
    <PaymentEmptyState />
  ) : (
    <>
      {!!lateInvoices?.length ? (
        <ListLateInvoices
          invoices={lateInvoices}
          renderAction={(invoiceId, invoiceStatus) => (
            <RouterLink to={webappRoutes.root.wallet().payments().invoice().byId(invoiceId).path}>
              <Button size='small'>{invoiceStatus === 'SENT' ? tMain('action.pay') : tMain('action.retry')}</Button>
            </RouterLink>
          )}
        />
      ) : undefined}
      {!!paymentMethods?.length ? (
        <ListPaymentMethods
          paymentMethods={paymentMethods}
          renderViewAction={(payInMethodId) => (
            <Link to={`${PAYMENT_URL}/${payInMethodId}`}>
              <TextButton>{tBase('actions.view')}</TextButton>
            </Link>
          )}
        />
      ) : undefined}
      <TopInvoicesTable
        invoices={topLegacyInvoices?.data}
        type='RENTAL'
        invoicesUrl={invoicesUrl}
        onTopActionClick={() => navigate('./rental-history')}
      />
      <TopInvoicesTable
        invoices={topInvoices?.data}
        type='PAYMENTS'
        invoicesUrl={invoicesUrl}
        onTopActionClick={() => navigate('./history')}
      />
    </>
  )
}

const PaymentsScreen: React.FC = () => {
  const { tMain } = useT({ screenName: 'screens.payments' })
  const { tMain: tNavigation } = useT({ screenName: 'sideMenu.navItem' })

  return (
    <AccountPageLayout
      breadcrumbItems={[
        {
          label: tNavigation('wallet'),
        },
        {
          label: tNavigation('payments'),
        },
      ]}
    >
      <PageSection1 hasDivider={false} title={tMain(`title`)}>
        <PaymentsScreenContent />
      </PageSection1>
    </AccountPageLayout>
  )
}

export { PaymentsScreen }
