import React from 'react'

import { webappRoutes as accountRoutes } from '@guiker/account-shared'
import { Payout } from '@guiker/payout-app-components'
import {
  mainPathBuilder,
  NOTIFIED_STATUS,
  PayoutMethod as PayoutMethodEntity,
  PayoutMethodStatus,
} from '@guiker/payout-shared'
import { payoutMethodInfoBuilder } from '@guiker/payout-shared'
import { Chip, Flex, IconTooltip, PBold, RouterLink, TextButton, useLayoutContext } from '@guiker/react-framework'

import { useT } from '../../i18n'
import { PayoutMethodStatusChip } from '../PayoutMethodStatusChip'

type PayoutMethodProps = {
  payoutMethod: PayoutMethodEntity
}

const PayoutMethodToolTip: React.FC<PayoutMethodProps> = ({ payoutMethod }) => {
  const { tMain } = useT({ screenName: 'components.listPayoutMethods' })
  if (!NOTIFIED_STATUS.includes(payoutMethod.status)) return
  return <IconTooltip title={tMain(`toolTip.${payoutMethod.status}`)} />
}

export const PayoutMethod: React.FC<PayoutMethodProps> = ({ payoutMethod }) => {
  const { tMain, tBase } = useT({ screenName: 'components.listPayoutMethods' })
  const label = payoutMethodInfoBuilder(payoutMethod).getDisplay()
  const { isMobile } = useLayoutContext()

  return (
    <Flex justifyContent='space-between' alignItems='center'>
      <Flex gap={2} alignItems='center'>
        <Payout.PayoutMethodIcon payoutMethod={payoutMethod} />
        <Flex gap={isMobile ? 2 : 3} alignItems='center'>
          <PBold mb={0} whiteSpace='nowrap'>
            {label}
          </PBold>
          <Chip variant='outlined' color='info' size='small'>
            {tBase(`currency.${payoutMethod.currency}`)}
          </Chip>
          <PayoutMethodStatusChip payoutMethod={payoutMethod} />
          <PayoutMethodToolTip payoutMethod={payoutMethod} />
        </Flex>
      </Flex>
      {payoutMethod.status === PayoutMethodStatus.ENABLED ? (
        <RouterLink to={accountRoutes.root.wallet().payoutPreferences().payoutMethod(payoutMethod.id).path}>
          <TextButton>{tMain('seeDetails')}</TextButton>
        </RouterLink>
      ) : payoutMethod.status === PayoutMethodStatus.CHANGE_REQUESTED ? (
        <RouterLink to={mainPathBuilder.root.byId(payoutMethod.id).path}>
          <TextButton>{tMain('editPayout')}</TextButton>
        </RouterLink>
      ) : payoutMethod.status === PayoutMethodStatus.CREATED ? (
        <RouterLink to={mainPathBuilder.root.byId(payoutMethod.id).path}>
          <TextButton>{tMain('completePayout')}</TextButton>
        </RouterLink>
      ) : (
        <></>
      )}
    </Flex>
  )
}
