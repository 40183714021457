import React from 'react'

import { AccountPageLayout } from '@guiker/account-components'
import { CurrencyISO } from '@guiker/money'
import { Capabilities, mainPathBuilder, SupportedCountries } from '@guiker/payout-shared'
import {
  Button,
  Flex,
  FullScreenSpinner,
  Link,
  PageSection1,
  Pagination,
  theme,
  useMediaQuery,
  useQuery,
} from '@guiker/react-framework'

import {
  CurrencyHighlightBody,
  Highlight,
  ListPayoutMethods,
  PayoutPreferencesEmptyState,
  PayoutTable,
} from '../../components'
import { PayoutContextProvider, useAuthenticatedPayoutApiClient, usePayoutContext } from '../../hooks'
import { useT } from '../../i18n'

const PayoutPreferencesScreenContent: React.FC = () => {
  const { tMain } = useT({ screenName: 'screens.payoutPreferences' })
  const apiClient = useAuthenticatedPayoutApiClient()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { payoutMethods, isLoading } = usePayoutContext()

  const hasCADPayoutMethods = payoutMethods?.find((p) => p.currency === CurrencyISO.CAD)
  const hasUSDPayoutMethods = payoutMethods?.find((p) => p.currency === CurrencyISO.USD)

  const fetcher = (pagination: Pagination) => apiClient.readAllPayouts({ queryParams: pagination })

  const { data: kpis } = useQuery(['readPayoutKPIs'], () => apiClient.readPayoutKPIs({}), {
    enabled: !!payoutMethods?.length,
  })

  return (
    <AccountPageLayout>
      <PageSection1
        title={tMain(`title`)}
        topActions={
          !payoutMethods?.length &&
          !isLoading && (
            <Link to={mainPathBuilder.root.create.path}>
              <Button>{tMain(`cta`)}</Button>
            </Link>
          )
        }
      >
        {isLoading ? (
          <FullScreenSpinner />
        ) : !payoutMethods?.length ? (
          <PayoutPreferencesEmptyState />
        ) : (
          <Flex flexDirection='column' gap={10}>
            <Flex gap={isMobile ? 3 : 10} flexDirection={isMobile ? 'column' : 'row'}>
              <Highlight
                header={tMain('total')}
                value={
                  <>
                    {hasCADPayoutMethods && (
                      <CurrencyHighlightBody currency={CurrencyISO.CAD} amount={kpis?.CAD.pending} />
                    )}
                    {hasUSDPayoutMethods && (
                      <CurrencyHighlightBody currency={CurrencyISO.USD} amount={kpis?.USD.pending} />
                    )}
                  </>
                }
              />
              <Highlight
                header={tMain('inTransit')}
                value={
                  <>
                    {hasCADPayoutMethods && (
                      <CurrencyHighlightBody currency={CurrencyISO.CAD} amount={kpis?.CAD.inTransit} />
                    )}
                    {hasUSDPayoutMethods && (
                      <CurrencyHighlightBody currency={CurrencyISO.USD} amount={kpis?.USD.inTransit} />
                    )}
                  </>
                }
              />
            </Flex>

            <ListPayoutMethods />
            <PayoutTable fetcher={fetcher} />
          </Flex>
        )}
      </PageSection1>
    </AccountPageLayout>
  )
}

const PayoutPreferencesScreen: React.FC = () => {
  return (
    <PayoutContextProvider
      regions={[SupportedCountries.Canada, SupportedCountries.UnitedStates]}
      capabilities={[Capabilities.INVESTMENT, Capabilities.RENTAL]}
    >
      <PayoutPreferencesScreenContent />
    </PayoutContextProvider>
  )
}

export { PayoutPreferencesScreen }
