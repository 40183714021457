import React from 'react'

import { deprecatedRouterBuilder, RedirectNoIndex } from '@guiker/react-framework'

import {
  FundingAccountScreen,
  PaymentHistoryScreen,
  PaymentMethodScreen,
  PaymentsScreen,
  PayoutMethodScreen,
  PayoutPreferencesScreen,
  RentalPaymentHistoryScreen,
  ViewInvoiceScreen,
} from '../screens'
import { FundingAccountNestedRouter } from './FundingAccountNestedRouter'
import { InvoiceNestedRouter } from './InvoiceNestedRouter'

export const { routes, Router } = deprecatedRouterBuilder({
  root: {
    path: '/',
    Component: () => <RedirectNoIndex path={`./funding-account`} />,
  },
  fundingAccount: {
    path: '/funding-account',
    Component: FundingAccountNestedRouter,
    routes: {
      root: {
        path: '/',
        Component: FundingAccountScreen,
      },
    },
  },
  payoutPreferences: {
    path: '/payout-preferences',
    routes: {
      root: {
        path: '/',
        Component: PayoutPreferencesScreen,
      },
      payoutMethod: {
        path: '/payout-method/:id',
        Component: PayoutMethodScreen,
      },
    },
  },
  payments: {
    path: '/payments',
    isOutlet: true,
    routes: {
      root: {
        path: '/',
        Component: PaymentsScreen,
      },
      paymentMethod: {
        path: '/:id',
        Component: PaymentMethodScreen,
      },
      invoice: {
        path: '/invoices/:id',
        Component: InvoiceNestedRouter,
        isOutlet: true,
        routes: {
          viewInvoice: {
            path: '/',
            Component: ViewInvoiceScreen,
          },
        },
      },
      history: {
        path: '/history',
        Component: PaymentHistoryScreen,
      },
      rentalHistory: {
        path: '/rental-history',
        Component: RentalPaymentHistoryScreen,
      },
    },
  },
})
