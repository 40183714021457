import React from 'react'

import { usePayoutContext } from '../../hooks'
import { useT } from '../../i18n'
import { Divider, Flex, PageSection2 } from '..'
import { PayoutMethod } from './PayoutMethod'

const ListPayoutMethods: React.FC = () => {
  const { tMain } = useT({ screenName: 'components.listPayoutMethods' })
  const { payoutMethods } = usePayoutContext()

  return (
    <PageSection2 title={tMain('title')}>
      <Flex flexDirection='column' gap={2}>
        {payoutMethods.map((payoutMethod, index) => (
          <React.Fragment key={payoutMethod.id}>
            <PayoutMethod payoutMethod={payoutMethod} />
            {index < payoutMethods.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </Flex>
    </PageSection2>
  )
}

export { ListPayoutMethods }
